@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import './variables';
@import './mixins';
@import './component/app';
@import './component/login';
@import './component/header';
@import './component/notifications';
@import './component/userbox';
@import './component/layout';
@import './component/suspenseloader';
@import './component/sidebar';
@import './component/app';
@import './component/table';
@import './component/modal';
@import './component/bot_modal';
@import './fontStyle.scss';
@import './component/settings';
@import './component/video_recorder';

//common classes goes here
body {
  overflow-y: scroll !important;
  padding: 0 !important;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//multiple file upload icon size

.text-center {
  text-align: center;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}
.textNone {
  text-transform: none !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-center {
  @include flex-column-center;
}

.flex-basic-start {
  justify-content: start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}
.textweight {
  @include font-weight-bold;
}
.textalign {
  text-align: center;
}

//height
.h-100 {
  height: 100%;
}
//width per clasess
@for $i from 0 through 100 {
  .w-#{$i} {
    width: round(percentage($i/100));
  }
}

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mr-#{$i} {
    margin-right: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .ml-#{$i} {
    margin-left: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mb-#{$i} {
    margin-bottom: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .p-#{$i} {
    padding: #{($i/16)}rem !important;
  }
}
.d-flex {
  display: flex;
}

.m-width {
  min-width: 350 !important ;
}

.sidebarwrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.baselayout {
  flex: 1;
  height: 100%;
  &.admin-login {
    background-color: $adminLoginbackgroundColor;
  }
}

.selectformcontrol {
  width: 100%;
}

.overflow_auto {
  overflow: auto;
}
.flex_1 {
  flex: 1;
}
.overflow_x_hidden {
  overflow-x: hidden;
}
.alignitem {
  align-items: center;
}

.loginBox {
  position: relative;
  &::after {
    height: 100%;
    content: '';
    position: absolute;
    width: 1px;
    right: 0px;
    background-color: $borderBgColor;
  }
}

.commonListScroll {
  @include commonScrollBar;
  overflow: auto;
}

//password strength meter
.passStrengthWeak {
  background-color: $progressErrorBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressError !important;
  }
}
.passStrengthFair {
  background-color: $progressWarningBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressWarning !important;
  }
}
.passStrengthGood {
  background-color: $progressStrongBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressStrong !important;
  }
}
.passStrengthStrong {
  background-color: $progressSuccessBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressSuccess !important;
  }
}
.errorState {
  color: $errorTextColor !important;
}
.letter-spacing {
  letter-spacing: 0.5px;
}

/* .assessments-main-container {
  margin-left: 2rem;
  margin-top: 2rem;
} */

.question-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-right: 2rem;
  padding-bottom: 1rem;
  padding: 10px 20px 10px 20px;
  gap: 0px;
  border-radius: 12px 0px 0px 0px;
  font-family: Source Sans Pro;
}

.record-response-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-right: 2px;
    font-weight: 300;
    font-size: 13px;
    color: #d9534f;
    font-family: $font-family-base;

    .icon {
      width: 15px;
      height: 15px;
      margin-right: 2px;
    }
  }
}

.button-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-info-container-2 {
  margin-left: 2rem;
}

.modal-info-main-container {
  padding: 1rem 2rem;
  border-bottom: 3px solid rgb(0, 0, 0, 0.2);
}

.modal-guidelines-main-container {
  align-items: center;
  padding: 2rem 3rem;
  padding-bottom: 0;
}

.modal-guidelines-container {
  background-color: rgba(128, 128, 128, 0.35);
  border: 2px solid rgb(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.modal-guidelines-heading-container {
  padding: 1rem;
  border-bottom: 2px solid rgb(0, 0, 0, 0.2);
}

.modal-image-container,
.modal-guidelines-text-container {
  flex: 1;
}
//guidelines and info modal
.css-ypiqx9-MuiDialogContent-root {
  padding: 0 !important;
}

.css-yfv2yd-MuiTypography-root-MuiDialogTitle-root {
  padding: 0 !important;
}

.modal-main-container {
  display: flex;
  flex-direction: column;
}

.modal-guidelines-text-container {
  padding: 2rem;
}

.guidelines-simple-dialog {
  padding: 0 !important;
}

.modal-heading-container {
  background-color: rgb(255, 255, 255);
  padding: 1rem 2rem;
}

.guidelines-modal-heading {
  font-family: 'Rubik', sans-serif !important;
  font-weight: 500 !important;
  font-size: 24px !important;
}

.modal-body-container {
  display: flex;
  padding: 3rem;
  padding-bottom: 1rem;
  background: linear-gradient(
    to bottom,
    rgba(67, 57, 242, 0.1),
    rgba(255, 255, 255, 0.1)
  );
}

.modal-body-details-container {
  display: flex;
  flex-direction: column;
  flex: 10;
}

.modal-body-details-field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-body-details-field-title-container {
  display: flex;
  align-items: baseline;
}

.modal-body-details-field-title-box {
  height: 12px;
  width: 3px;
  background-color: rgba(200, 142, 251, 1);
  border-radius: 30px;
}

.modal-body-details-field-title-text {
  margin-left: 10px !important;
  color: rgba(76, 91, 103, 1);
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-family: 'Source Sans 3', sans-serif !important;
}

.modal-body-details-field-value-text {
  margin-top: 5px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: 'Rubik', sans-serif !important;
}

.modal-body-guidelines-main-container {
  flex: 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 2rem;
  border-radius: 8px;
  padding: 2rem;
}

.modal-body-guidelines-heading {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 24px !important;
  font-weight: bolder !important;
  width: fit-content !important;
}

.modal-body-guidelines-container {
  display: flex;
  flex-direction: column;
}

.modal-body-guideline {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
}

.modal-body-guideline-text-container {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
}

.modal-body-guideline-text {
  font-family: 'Source Sans 3', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.modal-gotit-button-container {
  margin-top: 3rem;
}

//video recorder
.video-recorder-main-container {
  margin-top: 3vh;
}

.video-recording-and-display-box {
  margin-top: 2vh;
}

.question-and-video-recorder-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 1rem;
  background: #fff;
  // background-color: rgba(128, 128, 128, 0.2);
}

// record assessment page
.question-box-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  margin-bottom: 10px;
}

.question-box-done-questions {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4c5b67;
  margin-bottom: 10px;
}

.question-video-recorder {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000 !important;
}

.done-questions {
  padding: 2px 12px 2px 12px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #3aac38;
  color: #3aac38;
  background: #edfaeb;
}

.question-box-current-question {
  flex: 15;
  margin-left: 1rem;
}

/* .record-assessment-video-recorder-box {
  margin-top: 5vh;
} */

.cancel-submit-button-container {
  /* display: flex;
  width: fit-content;
  margin-top: 3vh; */
  display: flex;
  width: -moz-fit-content;
  margin-top: 3vh;
  justify-content: center;
  align-items: center;
}

.record-assessment-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
}

.cancel-modal-heading-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.cancel-modal-body-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 40px 25px 40px;
}

.cancel-modal-body-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 85%;
}

.cancel-modal-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.card-border {
  border: 1px solid $card-border-grey;
}
.css-dglpug-MuiPaper-root-MuiCard-root,
.css-dv61ps-MuiPaper-root-MuiCard-root {
  box-shadow: 0px 6px 12px rgba(159, 162, 191, 0.15),
    0px 1px 1px rgba(159, 162, 191, 0.25);
}

.header {
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 32px 4px #161a1d1a;
}
.header-logo {
  // width: 23vh;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  margin-left: 50px;
}
.header-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: $table-header;
  font-family: $font-family-base;
}

//Legend
.legend {
  margin-bottom: 10px !important;
  padding: 10px 12px !important;
}
.legend-label {
  font-size: 14px;
  font-weight: 500;
}
.legend-value {
  font-size: 12px;
  font-weight: 500;
}

//Question Header

.question-header {
  padding-bottom: 15px;
  border-bottom: 1px solid $question-header-border-bottom;
}
.question-header-padding {
  padding: 0 10px;
  margin-left: 20px;
}
.justify-content-space-around {
  justify-content: space-around;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.back-arrow-icon {
  font-size: 24px;
  color: $black;
  cursor: pointer;
}
.question {
  margin-top: 15px;
  line-height: 24px;
  font-size: 18px;
  font-family: $font-family-base;
  font-weight: 600;
  color: $black;
}
.download-button {
  margin-left: auto;
}

//DonutCard
.icon {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  align-items: center;
}

.text-icon-color {
  color: $text-icon-color;
}
.audio-icon-color {
  color: $audio-icon-color;
}
.video-icon-color {
  color: $video-icon-color;
}

.donut-card-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: $black;
}
.donut-card-width {
  // width: 43.75rem;
  margin-bottom: 9px;
}

//Description

.description-label {
  font-weight: 600;
  font-size: 16px;
}
.description-subLabel-box {
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-right: 8px;
  height: 3vh;
}
.description-subLabel-square {
  width: 0.8vh;
  height: 0.8vh;
  border-radius: 2px;
}
.description-subLabel {
  font-weight: 600;
  font-size: 10px;
}

//Reports

.container-title {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: $black;
}
.container-card {
  width: 115vh;
  height: 50.5vh;
}
.reports-description {
  margin: 14px 33px 0 33px;
  font-weight: 600;
  font-size: 16px;
}

.bar-plot-card {
  // width: 115vh;
  // height: 69vh;
}
.common-background-gradient {
  background: linear-gradient(
    to bottom,
    rgba(67, 57, 242, 0.1),
    rgba(255, 255, 255, 0.1)
  );
}

//retake assessment modal
.retake-assessment-modal-main-container {
  display: flex;
  flex-direction: column;
  height: 55vh;
}

.retake-assessment-modal-heading-container {
  flex: 13;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(240, 243, 244, 1);
}

.retake-assessment-modal-heading-text {
  font-size: 24px !important;
  font-weight: 500 !important;
  font-family: 'Rubik', sans-serif !important;
  line-height: 28px !important;
  color: rgba(18, 21, 23, 1);
}

.retake-assessment-modal-body-container {
  flex: 79;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  text-align: center;
}

.retake-assessment-modal-body-text {
  margin-top: 1.5rem !important;
  font-family: 'Source Sans 3', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.retake-assessment-modal-buttons-container {
  flex: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(240, 243, 244, 1);
}

//Login

.loginImage {
  width: 350px;
}

.loginImageContainer {
  background: linear-gradient(
    to bottom,
    rgba(67, 57, 242, 0.1),
    rgba(255, 255, 255, 0.1)
  );
  height: 100vh;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.aboutContainer {
  font-family: $font-family-base;
  font-weight: 600;
  font-size: 30px;
  color: $black;
}

.aboutDescriptionContainer {
  font-size: 16px;
  margin: 0 auto;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: center;
  padding: 40px;
}

.formFieldLabel {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}
.loginFormGrid {
  height: 100%;
  background-color: $white;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.loginForm {
  margin-top: 300px;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.right-side {
  padding: 40px;
}
.right-side h1 {
  color: #d9534f;
  font-size: 36px;
  margin-bottom: 20px;
}
.right-side p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
}
.highlight {
  color: #d9534f;
  font-weight: bold;
}

.record-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
}
.record-icon {
  height: 70px;
  width: 70px;
}

.button-icon {
  margin-right: 7px;
}
